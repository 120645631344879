import { graphql, Link, navigate } from "gatsby";
import React from "react";
import Seo from "../components/seo";
import Layout from "../components/layout";
import styled from "styled-components";
import { lilac } from "../shared/colors";
import Responsive from "../components/responsive";

const Wrapper = styled.section`
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  ${Responsive};
`;

const Content = styled.div`
`;
const Subtitle = styled.h2`
  font-size: 1.5em;
  color: ${lilac};
  font-weight: 500;
  text-align: center;
`;

export default ({data}: {data: any}) => {
    const blog = data.markdownRemark;
    return (
        <Layout title={blog.frontmatter.title} transparent={true} header={blog.frontmatter.image} >
            <Seo title={blog.frontmatter.title} />
            <Wrapper>
            <Subtitle>{blog.frontmatter.subtitle}</Subtitle>
        <Link to="#" onClick={() => window.history.back()}>{"<"} Back</Link>
            <Content dangerouslySetInnerHTML={{ __html: blog.html }} />
            </Wrapper>
        </Layout>)
}



export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        subtitle
        image
      }
    }
  }
`;
